<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg5 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Fornecedor" v-model="pedido.nomeFornecedor" :readonly="true"></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Frete" v-model="pedido.tipoFrete" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg7 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Data do pedido"
            v-model="pedido.dataPedidoString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Previsão de entrega"
            v-model="pedido.dataPrevistaEntregaString"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Quantidade total"
            v-model="pedido.quantidade"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Valor do frete" v-model="pedido.valorFrete" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor do pedido" v-model="totalPedido" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headersProduto" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td><money style="width:80px" class="mr-2" v-model="props.item.precoUnitario" v-bind="money"></money></td>
              <td>
                {{ (props.item.total = props.item.quantidade * props.item.precoUnitario).toLocaleString() }}
              </td>
              <td v-if="props.item.entregue">{{ props.item.dataEntregaString }}</td>
              <td v-else>
                <v-text-field
                  name="dataEntrega"
                  v-mask="'##/##/####'"
                  v-model="props.item.dataEntrega"
                  required
                ></v-text-field>
              </td>
              <td>{{ props.item.grade }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-select
            class="mr-2"
            :items="formasPagamento"
            item-value="Id"
            item-text="FormaPagamento"
            label="Forma de pagamento"
            v-model="idFormaPagamento"
            :rules="formaPagamentoRule"
            return-object
          ></v-select>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <money class="mr-2" v-model="valorPagamento" v-bind="money"></money>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevPagamento"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataPagamento"
          >
            <v-text-field
              slot="activator"
              label="Data pagamento"
              v-model="dataPagamento"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataPagamento" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevPagamento = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataPagamento)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn max-width="20px" depressed icon dark color="primary" small @click="adicionarPagamento">
            <v-icon small>library_add</v-icon>
          </v-btn>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headersPagamento" :items="pagamentos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.id }}</td>
              <td v-if="props.item.valor != undefined">
                {{
                  props.item.valor.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  })
                }}
              </td>
              <td v-else>{{ props.item.valorPagoString }}</td>
              <td>{{ props.item.formaPagamento }}</td>
              <td v-if="props.item.dataPagamentoString != undefined">{{ props.item.dataPagamentoString }}</td>
              <td v-else>{{ props.item.dataPagamento }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn @click="voltar" outline color="primary">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { Money } from "v-money"
import Pagamento from "../../domain/saida/Pagamento"
import FormaPagamento from "../../domain/saida/FormaPagamento"
import ProgressCircular from "../progressCircular/progressCircular"
import { ServicoPedido } from "../../servicos/servicoPedido"
const servicoPedido = new ServicoPedido()
export default {
  components: {
    ProgressCircular,
    Money
  },
  data() {
    return {
      pedido: "",
      produtos: [],
      pagamentos: [],
      dtPrevPagamento: false,
      dataPagamento: null,
      valorPagamento: null,
      idFormaPagamento: null,
      formasPagamento: [],
      pagamento: new Pagamento(),
      headersProduto: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Nome do produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Quantidade", align: "left", sortable: false, value: "quantidade" },
        { text: "Preço un.", align: "left", sortable: false, value: "precoUnitario" },
        { text: "Total", align: "left", value: "valorTotal", sortable: false },
        { text: "Data da entrega", align: "left", value: "dataEntregaString", sortable: false },
        { text: "Grade", align: "left", sortable: false }
      ],
      headersPagamento: [
        { text: "Valor", align: "left", sortable: false, value: "valor" },
        { text: "Forma de pagamento", align: "left", sortable: false, value: "formaPagamento" },
        { text: "Data", align: "left", sortable: false, value: "dataPagamentoString" }
      ],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " # ",
        precision: 2,
        masked: false
      }
    }
  },
  created() {
    this.getFormasPagamento()
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoPedido.buscarPorId(this.id).then(res => {
        this.pedido = res.data
        this.produtos = res.data.produtos
        this.pagamentos = res.data.pagamentos
        this.pedido.valorFrete = this.pedido.valorFrete.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })
        this.pedido.valorPedido = this.pedido.valorPedido.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        })
      })
    }
  },
  computed: {
    totalPedido: function() {
      var total = 0
      this.produtos.forEach(prod => {
        total += prod.quantidade * prod.precoUnitario
      })
      return total.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
    }
  },
  methods: {
    getFormasPagamento() {
      var dinheiro = new FormaPagamento()
      dinheiro.Id = 1
      dinheiro.FormaPagamento = "Dinheiro"
      var cartao = new FormaPagamento()
      cartao.Id = 2
      cartao.FormaPagamento = "Cartão"
      var deposito = new FormaPagamento()
      deposito.Id = 3
      deposito.FormaPagamento = "Depósito"
      var transf = new FormaPagamento()
      transf.Id = 4
      transf.FormaPagamento = "Transferência"
      var cheque = new FormaPagamento()
      cheque.Id = 5
      cheque.FormaPagamento = "Cheque"
      this.formasPagamento.push(dinheiro)
      this.formasPagamento.push(cartao)
      this.formasPagamento.push(deposito)
      this.formasPagamento.push(transf)
      this.formasPagamento.push(cheque)
    },
    adicionarPagamento() {
      this.pagamento.ValorPago = this.valorPagamento
      this.pagamento.IdFormaPagamento = parseInt(this.idFormaPagamento.Id)
      this.pagamento.FormaPagamento = this.idFormaPagamento.FormaPagamento
      this.pagamento.DataPagamento = this.dataPagamento
      var novoPagamento = new Pagamento()

      this.valorPagamento = 0
      this.dataPagamento = null

      novoPagamento.idFormaPagamento = this.pagamento.IdFormaPagamento
      novoPagamento.dataPagamento = this.pagamento.DataPagamento
      novoPagamento.formaPagamento = this.pagamento.FormaPagamento
      novoPagamento.valorPago = this.pagamento.ValorPago
      novoPagamento.valorPagoString = novoPagamento.valorPago.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      })

      this.pagamentos.push(novoPagamento)
    },
    voltar() {
      this.$router.push({ name: "Pedidos" })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    submit() {
      this.abrirProgressCircular()
      this.pedido.valorPedido = parseFloat(
        this.totalPedido
          .replace("R$", "")
          .replace(".", "")
          .replace(",", ".")
      )
      this.pedido.valorFrete = parseFloat(
        this.pedido.valorFrete
          .replace("R$", "")
          .replace(".", "")
          .replace(",", ".")
      )

      this.produtos.forEach(prod => {
        prod.quantidade = parseInt(prod.quantidade)
        prod.precoCompra = prod.precoUnitario
        if (prod.dataEntrega != undefined) {
          var from = prod.dataEntrega.split("/")
          prod.dataEntrega = new Date(from[2], from[1] - 1, from[0])
        }
      })
      this.pagamentos.forEach(prod => {
        if (prod.id == undefined) {
          prod.FormaPagamento = prod.formaPagamento
          prod.DataPagamento = prod.dataPagamento
          prod.IdFormaPagamento = prod.idFormaPagamento
          prod.ValorPago = prod.valorPago
        }
      })
      servicoPedido.salvar(this.pedido, this.produtos, this.pagamentos).then(
        res => {
          this.fecharProgressCircular()
          if (res.status === 200) {
            this.voltar()
          }
        },
        err => {
          this.fecharProgressCircular()
      // eslint-disable-next-line
          console.log(err)
        }
      )
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
